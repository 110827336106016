var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alert-modal-bg" }, [
    _c(
      "div",
      { staticClass: "alert-modal-content" },
      [
        _c("font-awesome-icon", {
          staticClass: "alert-modal-icon",
          attrs: { icon: "fa-solid fa-circle-xmark" },
        }),
        _c("h1", { staticClass: "alert-modal-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("not_approve_request_title")) + " "),
        ]),
        _c("p", { staticClass: "alert-modal-text" }, [
          _vm._v(" " + _vm._s(_vm.$t("check_the_fields_below")) + " "),
        ]),
        _c("ul", { staticClass: "alert-modal-text alert-modal-list-items" }, [
          _c("li", [
            _vm._v(_vm._s(_vm.$t("not_approve_request_check_vehicle"))),
          ]),
          _vm.featureFlag
            ? _c("li", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("not_approve_request_check_adhesion_term")) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.featureFlag
            ? _c("li", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "not_approve_request_check_newsletter_subscription"
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.featureFlag
            ? _c("li", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("not_approve_request_check_stock_type_id")) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "alert-modal-btn-close" },
          [
            _c("NewDxaButton", {
              attrs: { outline: true, title: _vm.$t("close") },
              on: { btnFunction: _vm.closeDialog },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("font-awesome-icon", {
          staticClass: "alert-modal-icon-close",
          attrs: { icon: "fa-solid fa-xmark" },
          on: { click: _vm.closeDialog },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }