import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VContainer,
        { attrs: { id: "comany-balance", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                icon: "mdi-cash-multiple",
                inline: "",
                color: "secondary",
              },
              scopedSlots: _vm._u([
                {
                  key: "heading",
                  fn: function () {
                    return [_c(VIcon, [_vm._v("mdi-cash-multiple")])]
                  },
                  proxy: true,
                },
                {
                  key: "after-heading",
                  fn: function () {
                    return [
                      _c("h1", { staticClass: "h5" }, [
                        _c("b", [_vm._v(_vm._s(_vm.$t("allocation_balance")))]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _c(
                        VRow,
                        { attrs: { align: "center" } },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(VCombobox, {
                                key: _vm.update,
                                attrs: {
                                  outlined: "",
                                  "hide-details": "",
                                  dense: "",
                                  items: _vm.companies,
                                  "item-text": "Name",
                                  "item-value": "CompanyId",
                                  label: _vm.$tc("company", 1),
                                },
                                model: {
                                  value: _vm.selectedCompany,
                                  callback: function ($$v) {
                                    _vm.selectedCompany = $$v
                                  },
                                  expression: "selectedCompany",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            [
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.currencyOptions,
                                  "item-text": "text",
                                  "item-value": "value",
                                  outlined: "",
                                  "hide-details": "",
                                  dense: "",
                                  label: _vm.$t("currency"),
                                },
                                model: {
                                  value: _vm.AllocationSummary.Currency,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.AllocationSummary,
                                      "Currency",
                                      $$v
                                    )
                                  },
                                  expression: "AllocationSummary.Currency",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            [
                              _c(VTextField, {
                                key: _vm.update,
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: _vm.$tc("range_date", 1),
                                  type: "date",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.AllocationSummary.InitialDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.AllocationSummary,
                                      "InitialDate",
                                      $$v
                                    )
                                  },
                                  expression: "AllocationSummary.InitialDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            [
                              _c(VTextField, {
                                key: _vm.update,
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  label: _vm.$tc("range_date", 2),
                                  type: "date",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.AllocationSummary.FinalDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.AllocationSummary,
                                      "FinalDate",
                                      $$v
                                    )
                                  },
                                  expression: "AllocationSummary.FinalDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "dxa-btn-secondary",
                                  attrs: {
                                    disabled: !!_vm.selectedCompany
                                      ? false
                                      : true,
                                    small: "",
                                  },
                                  on: { click: _vm.getCompanyBalance },
                                },
                                [_vm._v(_vm._s(_vm.$t("apply")))]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass: "dxa-btn-secondary",
                                  attrs: {
                                    disabled: !!_vm.selectedCompany
                                      ? false
                                      : true,
                                    small: "",
                                  },
                                  on: { click: _vm.cleanFilters },
                                },
                                [_vm._v(_vm._s(_vm.$t("clean")))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "d-flex",
                              staticStyle: {
                                "justify-content": "space-between",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c("div", { staticClass: "value-box-flex" }, [
                                _c("p", { staticClass: "h6 text-center" }, [
                                  _vm._v(_vm._s(_vm.$tc("confirmed", 1)) + ":"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "value-box-items" },
                                  _vm._l(
                                    _vm.confirmedSummary,
                                    function (item, i) {
                                      return _c(
                                        "span",
                                        { key: i, staticClass: "h4" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  item.currency,
                                                  item.value
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _c("div", { staticClass: "value-box-flex" }, [
                                _c("p", { staticClass: "h6 text-center" }, [
                                  _vm._v(_vm._s(_vm.$tc("pending", 1)) + ":"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "value-box-items" },
                                  _vm._l(
                                    _vm.pendingSummary,
                                    function (item, i) {
                                      return _c(
                                        "span",
                                        { key: i, staticClass: "h4" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  item.currency,
                                                  item.value
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                              _c("div", { staticClass: "value-box-flex" }, [
                                _c("p", { staticClass: "h6 text-center" }, [
                                  _vm._v(_vm._s(_vm.$t("total")) + ":"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "value-box-items" },
                                  _vm._l(_vm.totalSummary, function (item, i) {
                                    return _c(
                                      "span",
                                      { key: i, staticClass: "h4" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatCurrency(
                                                item.currency,
                                                item.value
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    VContainer,
                    [
                      _c(VProgressCircular, {
                        staticStyle: { "margin-left": "50%" },
                        attrs: {
                          indeterminate: "",
                          size: "70",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VContainer,
        { attrs: { id: "interest-table", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: {
                icon: "mdi-cash-multiple",
                inline: "",
                color: "secondary",
              },
              scopedSlots: _vm._u([
                {
                  key: "heading",
                  fn: function () {
                    return [_c(VIcon, [_vm._v("mdi-cash-multiple")])]
                  },
                  proxy: true,
                },
                {
                  key: "after-heading",
                  fn: function () {
                    return [
                      _c("h1", { staticClass: "h5" }, [
                        _c("b", [_vm._v(_vm._s(_vm.$t("requests")))]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                VTabs,
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(VTab, [_vm._v(_vm._s(_vm.$tc("company_interest", 2)))]),
                  _c(VTab, [
                    _vm._v(_vm._s(_vm.$t("user_preferences_change"))),
                  ]),
                ],
                1
              ),
              _c("AllocationRequestsTable", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab == 0,
                    expression: "tab == 0",
                  },
                ],
              }),
              _c("UserPreferenceChangeRequests", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab == 1,
                    expression: "tab == 1",
                  },
                ],
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }