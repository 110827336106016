import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            VForm,
            {
              ref: "form",
              attrs: { "lazy-validation": _vm.lazy },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.get_requests_by_page()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _vm.filter
                ? _c(
                    VRow,
                    { staticClass: "mt-6", attrs: { justify: "center" } },
                    [
                      _c(VTextField, {
                        staticClass: "mx-3",
                        staticStyle: { "max-width": "35%" },
                        attrs: {
                          dense: "",
                          outlined: "",
                          label: _vm.$t("name") + "/" + _vm.$t("email"),
                        },
                        model: {
                          value: _vm.name_filter,
                          callback: function ($$v) {
                            _vm.name_filter = $$v
                          },
                          expression: "name_filter",
                        },
                      }),
                      _c(VTextField, {
                        staticClass: "mx-3",
                        staticStyle: { "max-width": "35%" },
                        attrs: {
                          dense: "",
                          outlined: "",
                          label: _vm.$tc("company", 1),
                        },
                        model: {
                          value: _vm.company_filter,
                          callback: function ($$v) {
                            _vm.company_filter = $$v
                          },
                          expression: "company_filter",
                        },
                      }),
                      _c(VSelect, {
                        staticClass: "mx-3",
                        staticStyle: { "max-width": "15%" },
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.StatusFilterOptions,
                          "item-text": "text",
                          "item-value": "index",
                          label: _vm.$t("status"),
                        },
                        model: {
                          value: _vm.status_filter,
                          callback: function ($$v) {
                            _vm.status_filter = $$v
                          },
                          expression: "status_filter",
                        },
                      }),
                      _c(
                        VBtn,
                        {
                          staticClass: "mx-3 secondary",
                          attrs: { type: "submit" },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  [
                    _vm._v(" " + _vm._s(_vm.$t("name"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color: _vm.orderBy == 0 ? "primary" : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(0)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == 0
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _vm._v(" " + _vm._s(_vm.$t("email"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color: _vm.orderBy == 1 ? "primary" : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(1)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == 0
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _vm._v(" " + _vm._s(_vm.$tc("company", 1))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color: _vm.orderBy == 2 ? "primary" : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(2)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == 0
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "th",
                  [
                    _vm._v(" " + _vm._s(_vm.$t("date"))),
                    _c(
                      VIcon,
                      {
                        staticClass: "ml-2",
                        attrs: {
                          small: "",
                          color: _vm.orderBy == 3 ? "primary" : "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.header_click(3)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderByMode == 0
                              ? "mdi-arrow-up"
                              : "mdi-arrow-down"
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("change_user_preference"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.requests, function (req, index) {
                return _c("tr", { key: index }, [
                  _c(
                    "td",
                    [
                      req.User.IsAlpha
                        ? _c(VIcon, { attrs: { color: "secondary" } }, [
                            _vm._v("mdi-alpha"),
                          ])
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.show_name(req)) + " "),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(req.User.Email))]),
                  _c("td", [_vm._v(_vm._s(req.Company.Name))]),
                  _c("td", [_vm._v(_vm._s(req.Timestamp.split("T")[0]))]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      !req.Changed
                        ? _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1 secondary",
                              attrs: { "min-width": "0", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.change_preference(req)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-check"),
                              ]),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("preference_change_successful")) +
                                " "
                            ),
                          ]),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(VPagination, {
            attrs: { color: "secondary", length: _vm.total_pages },
            on: { input: _vm.changePage },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "var(--primary)" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }